// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-calendar-js": () => import("./../../../src/pages/calendar.js" /* webpackChunkName: "component---src-pages-calendar-js" */),
  "component---src-pages-classroom-js": () => import("./../../../src/pages/classroom.js" /* webpackChunkName: "component---src-pages-classroom-js" */),
  "component---src-pages-curriculum-js": () => import("./../../../src/pages/curriculum.js" /* webpackChunkName: "component---src-pages-curriculum-js" */),
  "component---src-pages-ginger-bread-man-js": () => import("./../../../src/pages/ginger-bread-man.js" /* webpackChunkName: "component---src-pages-ginger-bread-man-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-newsletter-js": () => import("./../../../src/pages/newsletter.js" /* webpackChunkName: "component---src-pages-newsletter-js" */),
  "component---src-pages-orientation-js": () => import("./../../../src/pages/orientation.js" /* webpackChunkName: "component---src-pages-orientation-js" */),
  "component---src-pages-preparation-js": () => import("./../../../src/pages/preparation.js" /* webpackChunkName: "component---src-pages-preparation-js" */),
  "component---src-pages-resources-js": () => import("./../../../src/pages/resources.js" /* webpackChunkName: "component---src-pages-resources-js" */),
  "component---src-templates-blog-list-template-2010-js": () => import("./../../../src/templates/BlogListTemplate2010.js" /* webpackChunkName: "component---src-templates-blog-list-template-2010-js" */),
  "component---src-templates-blog-list-template-2011-js": () => import("./../../../src/templates/BlogListTemplate2011.js" /* webpackChunkName: "component---src-templates-blog-list-template-2011-js" */),
  "component---src-templates-blog-list-template-2012-js": () => import("./../../../src/templates/BlogListTemplate2012.js" /* webpackChunkName: "component---src-templates-blog-list-template-2012-js" */),
  "component---src-templates-blog-list-template-2013-js": () => import("./../../../src/templates/BlogListTemplate2013.js" /* webpackChunkName: "component---src-templates-blog-list-template-2013-js" */),
  "component---src-templates-blog-list-template-2014-js": () => import("./../../../src/templates/BlogListTemplate2014.js" /* webpackChunkName: "component---src-templates-blog-list-template-2014-js" */),
  "component---src-templates-blog-list-template-2015-js": () => import("./../../../src/templates/BlogListTemplate2015.js" /* webpackChunkName: "component---src-templates-blog-list-template-2015-js" */),
  "component---src-templates-blog-list-template-2016-js": () => import("./../../../src/templates/BlogListTemplate2016.js" /* webpackChunkName: "component---src-templates-blog-list-template-2016-js" */),
  "component---src-templates-blog-list-template-2017-km-js": () => import("./../../../src/templates/BlogListTemplate2017Km.js" /* webpackChunkName: "component---src-templates-blog-list-template-2017-km-js" */),
  "component---src-templates-blog-list-template-2017-kt-js": () => import("./../../../src/templates/BlogListTemplate2017Kt.js" /* webpackChunkName: "component---src-templates-blog-list-template-2017-kt-js" */),
  "component---src-templates-blog-list-template-2018-km-js": () => import("./../../../src/templates/BlogListTemplate2018Km.js" /* webpackChunkName: "component---src-templates-blog-list-template-2018-km-js" */),
  "component---src-templates-blog-list-template-2018-kt-js": () => import("./../../../src/templates/BlogListTemplate2018Kt.js" /* webpackChunkName: "component---src-templates-blog-list-template-2018-kt-js" */),
  "component---src-templates-blog-list-template-2019-km-js": () => import("./../../../src/templates/BlogListTemplate2019Km.js" /* webpackChunkName: "component---src-templates-blog-list-template-2019-km-js" */),
  "component---src-templates-blog-list-template-2019-kt-js": () => import("./../../../src/templates/BlogListTemplate2019Kt.js" /* webpackChunkName: "component---src-templates-blog-list-template-2019-kt-js" */),
  "component---src-templates-blog-list-template-2020-km-js": () => import("./../../../src/templates/BlogListTemplate2020Km.js" /* webpackChunkName: "component---src-templates-blog-list-template-2020-km-js" */),
  "component---src-templates-blog-list-template-2020-kt-js": () => import("./../../../src/templates/BlogListTemplate2020Kt.js" /* webpackChunkName: "component---src-templates-blog-list-template-2020-kt-js" */),
  "component---src-templates-blog-list-template-2021-km-js": () => import("./../../../src/templates/BlogListTemplate2021Km.js" /* webpackChunkName: "component---src-templates-blog-list-template-2021-km-js" */),
  "component---src-templates-blog-list-template-2021-kt-js": () => import("./../../../src/templates/BlogListTemplate2021Kt.js" /* webpackChunkName: "component---src-templates-blog-list-template-2021-kt-js" */),
  "component---src-templates-blog-list-template-2022-km-js": () => import("./../../../src/templates/BlogListTemplate2022Km.js" /* webpackChunkName: "component---src-templates-blog-list-template-2022-km-js" */),
  "component---src-templates-blog-list-template-2022-kt-js": () => import("./../../../src/templates/BlogListTemplate2022Kt.js" /* webpackChunkName: "component---src-templates-blog-list-template-2022-kt-js" */),
  "component---src-templates-blog-list-template-2023-km-js": () => import("./../../../src/templates/BlogListTemplate2023Km.js" /* webpackChunkName: "component---src-templates-blog-list-template-2023-km-js" */),
  "component---src-templates-blog-list-template-2023-kt-js": () => import("./../../../src/templates/BlogListTemplate2023Kt.js" /* webpackChunkName: "component---src-templates-blog-list-template-2023-kt-js" */)
}

